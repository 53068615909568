export default function SaveData(inputData, setLoading, token, setOpen, setContent) {
        setLoading(true)
        let domain;
        if (!process.env.REACT_APP_AI_BACKEND_HOST_URL) {
            domain = 'http://127.0.0.1:5555';
        } else {
            domain = process.env.REACT_APP_AI_BACKEND_HOST_URL;
        }
        let backendHostUrl = domain + "/sequential"
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer ' + token },
            body: JSON.stringify(inputData),
        };
        fetch(backendHostUrl, requestOptions)
            .then(function (body) {
                return body.text();
            })
            .then(function (data) {
                if (data == 'no success') {
                    alert(data);
                } else {
                    setLoading(false)
                    setOpen(true)
                    setContent(data)
//                    window.location.href = "/SampleSizeCalculator";
                }
            })
}