import React, { Component, useState, useEffect } from "react"
import { Autocomplete, Box, Chip, Checkbox, Button, DataGrid, Divider, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, MenuItem, InputLabel, Select, TextField, Typography } from "@coolblue-development/becky"
import Edit from '@mui/icons-material/Edit';
import { platformArray } from "../../Form/StandardMetricsDimsMap";
import ListItemText from '@mui/material/ListItemText';
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, NavLink } from "react-router-dom";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import Switch from '@mui/material/Switch';
import { useAuth } from '@coolblue-development/react-cognito-sso-auth';
import  SaveData from '../BackendAPI/SaveData'
import { Dialog, DialogTitle, DialogContent, DialogContentText, IconButton} from '@coolblue-development/becky';
import { Cross} from '@coolblue-development/icons';


export default function CalculatorDetails() {
    const navigate = useNavigate();

    const types = ["Sample size", "Minimum Detectable Effect"]
    const [type, setType] = useState([''])

    const metrics = ['add_to_cart', 'placed_order_conversion', 'checkout_conversion_rate', 'contact_option_clicked',
    'solar_conversion', 'energy_conversion', 'charging_stations_conversion', 'custom']
    const [metric, setMetric] = useState(['placed_order_conversion'])

    const platforms = ['desktop', 'mobile', 'tablet', 'app']
    const [platform, setPlatform] = useState(['desktop', 'mobile', 'tablet'])

    const subsidiaries = ['NL', 'BE', 'DE']
    const [subsidiary, setSubsidiary] = useState(['NL', 'BE', 'DE'])

    const [traffic, setTraffic] = useState('')
    const [mde, setMDE] = useState('')
    const [variations, setVariations] = useState('1')

    const variable = {type:'MDE', metric:'placed_order_conversion', variations:'1', mde:'', traffic:'', event_name:'.*',
    context:'.*', feature:'.*', sub_feature:'.*', URL:'.*', platform:['desktop', 'mobile', 'tablet'],
    subsidiary:['NL', 'BE', 'DE']}
    const [inputData, setInputData] = useState(variable)
    const auth = useAuth()
    const token = auth.user.token;

    const [loading, setLoading] = React.useState(false);

    const [open, setOpen] = useState(false);
    const [content, setContent] = useState('');

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { target } = event;
        setInputData((prevState) => ({
            ...prevState,
            [target.name]: target.value,
        }));
    };

    const handleTypeChange = (event) => {
        const { target: { value }, } = event;
        setInputData((prevState) => ({
            ...prevState,
            ['type']: value,
        }));
        setType(value)
    };

    const handleMetricChange = (event) => {
        const { target: { value }, } = event;
        setInputData((prevState) => ({
            ...prevState,
            ['metric']: value,
        }));
        setMetric(value)
    };

    const handlePlatformChange = (event) => {
        const { target: { value }, } = event;
        setInputData((prevState) => ({
            ...prevState,
            ['platform']: value
        }));
        setPlatform(value)
    };

    const handleSubsidiaryChange = (event) => {
        const { target: { value }, } = event;
        setInputData((prevState) => ({
            ...prevState,
            ['subsidiary']: value,
        }));
        setSubsidiary(value)
    };

    const handleOpen = (): void => {
        setOpen(true);
    };

    const handleClose = (): void => {
        setOpen(false);
    };

    return (
    <Grid container>
        <Grid item xs={12}>
            <Box>
                <Typography variant="h2" gutterBottom component="div">
                    General information
                </Typography>
            </Box>
        </Grid>

    <Grid container xs={12} paddingTop={4} paddingBottom={4}>
        <Grid item xs={3} paddingRight={6}>
            <FormControl fullWidth>
                <InputLabel id="type">To calculate</InputLabel>
                <Select
                    labelId="type"
                    name="type"
                    defaultValue={type}
                    value={type}
                    onChange={handleTypeChange}
                    label="To calculate"
                    placeholder="Select one.."
                >
                {types.map((name) => (
                    <MenuItem value={name}>{name}</MenuItem>
                ))}
                </Select>
            </FormControl>
        </Grid>

        <Grid item xs={3} paddingRight={6}>
            <FormControl fullWidth>
                <InputLabel id="metric">Metric</InputLabel>
                <Select
                    labelId="metric"
                    name="metric"
                    defaultValue={metric}
                    value={metric}
                    onChange={handleMetricChange}
                    label="Metric"
                >
                {metrics.map((name) => (
                    <MenuItem value={name}>{name.replaceAll('_', ' ')}</MenuItem>
                ))}
                </Select>
            </FormControl>
        </Grid>
    </Grid>

    <Grid container xs={12} paddingTop={4} paddingBottom={4}>
        <Grid item xs={3} paddingRight={6}>
            <TextField fullWidth
               name="variations"
               label="Amount of variations"
               placeholder=""
               value={inputData.variations}
               onChange={e => { handleChange(e)}}
               placeholder=""
            />
        </Grid>

    {type === 'Minimum Detectable Effect' && (
        <Grid item xs={3} paddingRight={6}>
            <TextField fullWidth
               name="traffic"
               label="Traffic per day"
               value={inputData.traffic}
               onChange={e => { handleChange(e)}}
               placeholder=""
            />
        </Grid>
     )}

    {type === 'Sample size' && (
        <Grid item xs={3} paddingRight={6}>
            <TextField fullWidth
               name="mde"
               label="Minimum Detectable Effect"
               placeholder=""
               value={inputData.mde}
               onChange={e => { handleChange(e)}}
               placeholder=""
            />
        </Grid>
     )}

    </Grid>

    <Grid container xs={12} paddingTop={4} paddingBottom={4}>
        <Grid item xs={12}>
            <Box>
                <Typography variant="h2" gutterBottom component="div">
                    Test details
                </Typography>
            </Box>
        </Grid>
    </Grid>

    <Grid container xs={12} paddingTop={4} paddingBottom={4}>
        <Grid item xs={3} paddingRight={6}>
            <TextField fullWidth
               name="event_name"
               label="Event Name"
               value={inputData.event_name}
               onChange={e => { handleChange(e)}}
               placeholder=""
            />
        </Grid>

        <Grid item xs={3} paddingRight={6}>
            <TextField fullWidth
               name="context"
               label="Context"
               value={inputData.context}
               onChange={e => { handleChange(e)}}
               placeholder=""
            />
        </Grid>

        <Grid item xs={3} paddingRight={6}>
            <TextField fullWidth
               name="feature"
               label="Feature"
               value={inputData.feature}
               onChange={e => { handleChange(e)}}
               placeholder=""
            />
        </Grid>

        <Grid item xs={3} paddingRight={6}>
            <TextField fullWidth
               name="sub_feature"
               label="Sub-feature"
               value={inputData.sub_feature}
               onChange={e => { handleChange(e)}}
               placeholder=""
            />
        </Grid>
    </Grid>

    <Grid container xs={12} paddingTop={4} paddingBottom={4}>
        <Grid item xs={3} paddingRight={6}>
            <TextField fullWidth
               name="URL"
               label="Page Location"
               value={inputData.URL}
               onChange={e => { handleChange(e)}}
               placeholder=""
            />
        </Grid>

        <Grid item xs={4} paddingBottom={4} paddingRight={6}>
               <Autocomplete fullWidth
                 multiple
                 name="platform"
                 value={platform}
                 onChange={(event, newValue) => {
                   setPlatform(newValue);
                   setInputData((prevState) => ({
                       ...prevState,
                       ['platform']: newValue,
                   }));
                 }}
                 options={platforms}
                 filterSelectedOptions
                 renderInput={(params: AutocompleteRenderInputParams): ReactNode => (
                   <TextField {...params} label="Platform"/>
                 )}
               />
        </Grid>

        <Grid item xs={4} paddingBottom={4} paddingRight={6}>
               <Autocomplete fullWidth
                 multiple
                 name="subsidiary"
                 value={subsidiary}
                 onChange={(event, newValue) => {
                   setPlatform(newValue);
                   setInputData((prevState) => ({
                       ...prevState,
                       ['subsidiary']: newValue,
                   }));
                 }}
                 options={subsidiaries}
                 filterSelectedOptions
                 renderInput={(params: AutocompleteRenderInputParams): ReactNode => (
                   <TextField {...params} label="Subsidiary"/>
                 )}
               />
        </Grid>

    </Grid>

    <Box sx={{ height: 10 }} />
         <Divider />
            <Box sx={{ height: 10 }} />
            <Grid item xs={3} paddingTop={4}>
                <Box>
                    <Button
                        preset="callToAction"
                        loading={loading}
                        onClick={() => SaveData(inputData, setLoading, token, setOpen, setContent)}
                    >
                        Calculate
                    </Button>
                    <Dialog size="large" open={open} onClose={handleClose}>
                        <DialogTitle>
                        Result
                        <IconButton
                            onClick={handleClose}
                            preset="text"
                            size="small"
                            aria-label="Close dialog"
                        >
                          <Cross/>
                        </IconButton>
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText>{content}</DialogContentText>
                      </DialogContent>
                    </Dialog>
                </Box>
            </Grid>
    </Grid>
    );
}
