import { AuthProviderProps } from 'oidc-react';

export const removeSearchParamsFromUrl = () => {
  const { protocol, host, pathname } = window.location;
  const base = `${protocol}//${host}`;
  const newUrl = new URL(`${pathname}`, base).toString();
  window.history.replaceState(null, '', newUrl);
};

export const getAuthConfig = (): AuthProviderProps => {
  return {
    authority: "https://auth-service.eu-west-1.coolblue-testing.eu/",
    clientId: "custom-analysis-tool",
    redirectUri: window.location.href.split('?')[0],
    responseType: 'code',
    scope: "openid",
    automaticSilentRenew: true,
     onSignIn: userData => {
        removeSearchParamsFromUrl()
        try {console.log(userData.profile.sub)}
        catch {console.log('console.log failed')}
    }
  };
};



