import { createBrowserRouter, Outlet, Navigate } from 'react-router-dom';
import { ProtectedRoute } from '@coolblue-development/react-cognito-sso-auth';
import { routes } from './routes';

import Login from './Screens/Login';
import Home from './Screens/Home';

import CustomAnalysisHome from "./CustomAnalysis/Screens/CustomAnalysisHome";
import CustomMetric from "./CustomAnalysis/Screens/CustomMetric";
import CustomDimension from "./CustomAnalysis/Screens/CustomDimension";
import StandardMetric from "./CustomAnalysis/Screens/StandardMetric"
import StandardDimension from "./CustomAnalysis/Screens/StandardDimension"

import CustomAnalysisHome_GA4 from "./CustomAnalysis_GA4/Screens/CustomAnalysisHome";
import CustomMetric_GA4 from "./CustomAnalysis_GA4/Screens/CustomMetric";
import CustomDimension_GA4 from "./CustomAnalysis_GA4/Screens/CustomDimension";
import StandardMetric_GA4 from "./CustomAnalysis_GA4/Screens/StandardMetric"
import StandardDimension_GA4 from "./CustomAnalysis_GA4/Screens/StandardDimension"

import AIAnalysisHome from "./AIAnalysis/Screens/AIAnalysisHome"
import AIAnalysisDetails from "./AIAnalysis/Screens/Details"

import TrackingConfiguratorHome from "./TrackingConfigurator/Screens/Home"
import TrackingConfiguratorDetails from "./TrackingConfigurator/Screens/Details"

import SampleSizeCalculator from "./SampleSizeCalculator/Screens/Calculator"


export default createBrowserRouter([
    {
        path: routes.LOGIN,
        element: <Login />,
    },
    {
    path: routes.ROOT,
    element: (
      <ProtectedRoute
        renderLoadingPage={<div>Loading</div>}
        renderUnauthorizedPage={<Navigate to="/login" />}
      >
        <Outlet />
      </ProtectedRoute>
    ),
    errorElement: <Home />,
    children: [
        {
            path: routes.ROOT,
            element: <Home />,
        },
        {
            path: routes.CUSTOM_ANALYSIS,
            element: <CustomAnalysisHome />,
        },
        {
            path: routes.CUSTOM_ANALYSIS_GA4,
            element: <CustomAnalysisHome_GA4 />,
        },
        {
            path: routes.CUSTOM_ANALYSIS_CUSTOMMETRIC,
            element: <CustomMetric />,
        },
        {
            path: routes.CUSTOM_ANALYSIS_GA4_CUSTOMMETRIC,
            element: <CustomMetric_GA4 />,
        },
        {
            path: routes.CUSTOM_ANALYSIS_STANDARDMETRIC,
            element: <StandardMetric />,
        },
        {
            path: routes.CUSTOM_ANALYSIS_GA4_STANDARDMETRIC,
            element: <StandardMetric_GA4 />,
        },
        {
            path: routes.CUSTOM_ANALYSIS_CUSTOMDIMENSION,
            element: <CustomDimension />,
        },
        {
            path: routes.CUSTOM_ANALYSIS_GA4_CUSTOMDIMENSION,
            element: <CustomDimension_GA4 />,
        },
        {
            path: routes.CUSTOM_ANALYSIS_STANDARDDIMENSION,
            element: <StandardDimension />,
        },
        {
            path: routes.CUSTOM_ANALYSIS_GA4_STANDARDDIMENSION,
            element: <StandardDimension_GA4 />,
        },
        {
            path: routes.AI_ANALYSIS,
            element: <AIAnalysisHome />,
        },
        {
            path: routes.AI_ANALYSIS_DETAILS,
            element: <AIAnalysisDetails />,
        },
        {
            path: routes.TRACKING_CONFIGURATOR,
            element: <TrackingConfiguratorHome />,
        },
        {
            path: routes.TRACKING_CONFIGURATOR_DETAILS,
            element: <TrackingConfiguratorDetails />,
        },
        {
            path: routes.SAMPLE_SIZE_CALCULATOR,
            element: <SampleSizeCalculator />,
        },
        ],
    }
]);